import { Tr, Truncate } from '@functions/awds_function.js';
const Text_1 = () => {
  return (
    <div>
      <h2 className="heading">
        {Truncate(Tr('sections_1.last_news.heading'), 25)}
      </h2>
      <p className="w-[100%] max-w-2xl mr-10 rtl:mr-0 text-paragraph-color-black">
        {Truncate(Tr('sections_1.last_news.desc'), 160)}
      </p>
    </div>
  );
};
export default Text_1;
