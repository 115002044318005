import { lazy } from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import SideText1 from './ui/Side_Text_1';
import SideImage1 from './ui/Side_Image_1';

const Main_1 = ({ content, contactInfo }) => {
  return (
    <div className="flex flex-col-reverse justify-end w-[100%] pt-24 lg:pt-0 lg:max-h-[1000px] overflow-hidden">
      <div className=" container  grid lg:grid-cols-3 items-center lg:items-end lg:h-full ">
        <SideText1
          heading={(content && content.title) || ''}
          disc={(content && content.content) || ''}
          contactInfo={(contactInfo && contactInfo) || ''}
        />

        <div className="col-span-2 lg:order-2">
          {(content && (
            <SideImage1
              heading={(content && content.title) || ''}
              url={(content && content.background) || ''}
            />
          )) || (
            <Skeleton
              className="!w-full !h-[300px] lg:!mx-10 lg:!h-[400px] lg:!w-full mb-5 lg:mb-0"
              baseColor={'#8ff1d7'}
            />
          )}
        </div>
      </div>
    </div>
  );
};
export default Main_1;
