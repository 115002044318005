import { useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { fakeData, Editor, Smeller1, Social1 } from './IC.New';
import {
  useDispatch,
  useSelector,
  useEffect,
  loadPostAsync,
} from './../../../utils/Redux.Elements';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
const New_1 = () => {
  const { slug } = useParams();
  localStorage.setItem('slug', slug);

  const dispatch = useDispatch();
  const { post, errorMessage } = useSelector((state) => state.post);
  const { mains } = useSelector((state) => state.mains);

  useEffect(() => {
    dispatch(loadPostAsync());
  }, [dispatch]);

  (post && console.log('post slug : ', post)) ||
    console.log('error Message slug : ', errorMessage);
  return (
    <section className="pb-20 pt-36">
      <div className="container px-6 py-10 mx-auto">
        <div className="lg:flex lg:-mx-6">
          <div className="lg:w-3/4 lg:px-6">
            {(post && (
              <div>
                <div>
                  <h1 className="max-w-lg mt-4 text-4xl font-semibold leading-tight text-light-green ">
                    {post.data.title}
                  </h1>
                  <div className="flex items-center mt-6">
                    <div className="">
                      <p className=" text-dark-black mb-6 ">{post.data.desc}</p>
                    </div>
                  </div>
                </div>
                <div className="w-full max-h-[500px] overflow-hidden rounded-xl relative before:absolute before:content-['675×485'] before:z-10 before:w-full before:h-full before:left-[50%] before:top-[50%] -before:translate-x-[50%] -before:translate-y-[50%] before:opacity-50  before:text-4xl ">
                  <img
                    className="w-full object-center"
                    src={post.data.image_path}
                    alt="post content img"
                  />
                </div>
                {/* <div className="flex mt-5 gap-x-5">
                    {el.moreImage.length > 0 &&
                      el.moreImage.map((el, idx) => (
                        <div
                          onClick={() => setActive(idx)}
                          key={idx}
                          className={`${
                            active === idx ? 'active-img' : ''
                          } border-4 border-white rounded-xl overflow-hidden`}>
                          <img
                            src={require(`@assets/img/${el.url}`)}
                            width={80}
                            height={80}
                            alt={el.url.split('.', 1)}
                          />
                        </div>
                      ))}
                  </div> */}
              </div>
            )) || <Skeleton className="!w-full h-10 bg-light-green" />}
            <Editor contentEditor={(post && post.data.content) || ''} />
            <Social1 />
          </div>
          <div className="mt-8 lg:w-1/4 lg:mt-0 lg:mx-5  lg:px-10 md:ltr:border-l-2 md:rtl:border-r-2 md:rtl:border-l-0  max-h-96">
            <div>
              <h3 className="text-dark-black capitalize">Design instument</h3>
              <Link
                to={'#'}
                className="block mt-2 font-medium text-gray-700 hover:underline hover:text-gray-500  ">
                How to raise $100k+ by using blox ui kit on your design
              </Link>
            </div>
            <hr className="my-6 border-light-grey " />
            <div>
              <h3 className="text-dark-black capitalize">UI Resource</h3>
              <Link
                to={'#'}
                className="block mt-2 font-medium text-gray-700 hover:underline hover:text-gray-500  ">
                Should you creat UI Product by using Blox?
              </Link>
            </div>
            <hr className="my-6 border-light-grey " />
            <div>
              <h3 className="text-dark-black capitalize">Premium Collection</h3>
              <Link
                to={'#'}
                className="block mt-2 font-medium text-gray-700 hover:underline hover:text-gray-500  ">
                Top 10 Blocks you can get on Blox's collection.
              </Link>
            </div>
            <hr className="my-6 border-light-grey " />
            <div>
              <h3 className="text-dark-black capitalize">Premium kits</h3>
              <Link
                to={'#'}
                className="block mt-2 font-medium text-gray-700 hover:underline hover:text-gray-500  ">
                Top 10 Ui kit you can get on Blox's collection.
              </Link>
            </div>
          </div>
        </div>
        <Smeller1 contentCard={(mains && mains.posts) || ''} />
      </div>
    </section>
  );
};
export default New_1;
