import SectionCard1 from './ui/Section_Card';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

const Hospital_Major_1 = ({ content, contentCard }) => {
  return (
    <section>
      <div className="container py-10">
        {(content.title && (
          <h1 className="text-4xl font-semibold  text-light-green capitalize ">
            {content.title}
          </h1>
        )) || (
          <Skeleton
            baseColor={'#8ff1d7'}
            className="!mb-5  !h-8 !w-[70%]"
            count={1}
          />
        )}
        {(contentCard && (
          <div className="grid grid-cols-1 gap-5 justify-items-center mt-8 xl:mt-12 xl:gap-10 lg:grid-cols-3 ">
            <SectionCard1 contentCard={contentCard && contentCard} />
          </div>
        )) || (
          <Skeleton
            className={'!w-full  lg:!w-[250px] !h-[350px] ltr:!mr-5 mb-4   '}
            baseColor={'#8ff1d7'}
            inline
            count={3}
          />
        )}
      </div>
    </section>
  );
};
export default Hospital_Major_1;
