// هنا يتم تحديد endpoint
// هنا تحكم في اللغة التي ستظهر حسب i18
import apiClient from '../utils/apiClient';

class MainData {
  getAllData = (local) => {
    return apiClient().get(`home-data/${local}`);
  };
  getAllFaqCats = (local) => {
    return apiClient().get(`faqCats/${local}`);
  };
  getPost = (slug) => {
    return apiClient().get(`get-post/${slug}`);
  };
}

export default new MainData();
