import { Tr } from '@functions/awds_function.js';
import TranslateBtn1 from '../ui/Translate_btn_1';

const InputSearch_1 = () => {
  return (
    <>
      <TranslateBtn1 />
      <form className="flex items-center space-x-2 border-b-[1px] border-b-transparent hover:border-b-light-green  p-2">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-5 w-5 flex-none text-gray-300"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor">
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
          />
        </svg>

        <input
          className="w-full outline-none border-none appearance-none placeholder-gray-500 text-gray-500 sm:w-auto"
          type="text"
          placeholder={Tr('header.search')}
        />
      </form>
    </>
  );
};

export default InputSearch_1;
