import LeftArrow from '@assets/icons/left_arrow.svg';
import RightArrow from '@assets/icons/right_arrow.svg';
import calendarIcon from '@assets/icons/calendar.svg';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Lazy, Navigation } from 'swiper';
import { Tr, Truncate } from '@assets/js/awds_function';
import Button1 from '@views/include/ui/buttons/Button_1';

import 'swiper/css';
import 'swiper/css/lazy';
import 'swiper/css/grid';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import '@/index.css';
import { Link } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

const Card_Content_1 = ({ contentCard }) => {
  return (
    <div className="mt-0 ">
      <div className="flex ltr:justify-end rtl:justify-start  items-center    mt-10 gap-x-5 mb-5 ">
        <button className="  swiper-button-prev  hover:bg-hover-light-green rtl:!order-2">
          <img
            src={LeftArrow}
            alt="Left arrow icons"
            width={16}
            height={16}
            className="w-full h-auto max-w-[26px] "
          />
        </button>
        <button className=" rounded-md bg-light-green swiper-button-next !opacity-25 inline-block rtl:!order-1 hover:!opacity-100  ">
          <img
            src={RightArrow}
            alt="Right arrow icons"
            width={16}
            height={16}
            className="w-full h-auto max-w-[26px]"
          />
        </button>
      </div>
      <Swiper
        dir="ltr"
        className="flex flex-col-reverse swiper-container "
        slidesPerView={1}
        breakpoints={{
          200: { slidesPerView: 1, spaceBetween: 10 },
          450: { slidesPerView: 2, spaceBetween: 10 },
          640: { slidesPerView: 2, spaceBetween: 20 },
          768: { slidesPerView: 3, spaceBetween: 30 },
          1024: { slidesPerView: 4, spaceBetween: 40 },
        }}
        lazy={true}
        pagination={{ clickable: true }}
        navigation={{
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        }}
        autoplay={{ delay: 2500, disableOnInteraction: false }}
        modules={[Autoplay, Lazy, Navigation]}>
        {/* //! Card 1 */}
        {/* {feckData.map((el, ind) => ( <SwiperSlide key={ind} id="card" className=" bg-white max-w-[365px]  hover:border-light-green shadow-sm hover:shadow-md rounded-xl overflow-hidden mb-3"> <div className="max-w-[365px] relative "> <div id="blur-layer" className="absolute top-0 left-0 w-[100%] h-[100%] flex justify-center items-center"> <img className="opacity-0" id="show-icon" src={require(`@assets/icons/${el.showIcon}`)} alt={`${el.showIcon.split('.', 1)} icon`} /> </div> <img width="365" height="335" src={require(`@assets/img/${el.postImage}`)} alt={`${el.postImage.split('.', 1)} icon`} /> </div> <div className="mt-5 p-5 flex justify-start flex-col "> <h6 id="title" className="font-[600] text-2xl mb-4"> {Truncate(el.postTitle, 18)} </h6> <p id="text" className="text-paragraph-color mb-5 text-justify"> {Truncate(el.postDesc, 100)} </p> <span className="text-xs text-gray-500 border-t-[1px] pt-2 mt-2 inline-block  "> <bdi className="w-[5px] h-[5px] inline  "> <img className="object-cover  inline ltr:mr-1 rtl:ml-1 rtl:mr-1 opacity-50" src={calendarIcon} alt="calendar icon" /> </bdi> 13th August 2022 </span> <Link to={`/post/${ind + 1}`} className={'self-end'}> <Button1 txt="more" /> </Link> </div> </SwiperSlide> ))} */}
        {/* //! Card 2 */}
        {/* {feckData.map((el, ind) => ( <SwiperSlide className="max-w-xs rounded-md shadow-md bg-white mb-10 "> <img src={require(`@assets/img/${el.postImage}`)} alt="" className="object-cover object-center w-full rounded-t-md h-72 " /> <div className="flex flex-col justify-between p-6 space-y-8"> <div className="space-y-2"> <h2 className="text-3xl font-semibold tracking-wide"> Donec lectus leo </h2> <p> Curabitur luctus erat nunc, sed ullamcorper erat vestibulum eget. </p> </div> <span className="text-xs text-gray-500 border-t-[1px] pt-2 mt-2 inline-block  "> <bdi className="w-[5px] h-[5px] inline  "> <img className="object-cover  inline ltr:mr-1 rtl:ml-1 rtl:mr-1 opacity-50" src={calendarIcon} alt="calendar icon" /> </bdi> 13th August 2022 </span> <button type="button" className="flex items-center justify-center w-full p-3 font-semibold tracking-wide rounded-md bg-light-green text-white "> Read more </button> </div> </SwiperSlide> ))} */}
        {/* //! Card 3 */}
        {(contentCard &&
          contentCard.map((el, ind) => (
            <SwiperSlide
              key={ind}
              className="flex flex-col  p-6 space-y-6 overflow-hidden rounded-lg shadow-md bg-white mb-24 !w-[unset] max-w-[300px]">
              <div>
                <img
                  src={el.image_path}
                  alt=""
                  className="object-cover w-full  mb-4 h-60 sm:h-52 hover:scale-105  rounded-md "
                />
                <h2 className="mb-1 text-xl font-semibold ">
                  {Truncate(el.title, 50)}
                </h2>
                <p className="text-sm text-gray-600 mb-2 ">
                  {Truncate(el.desc, 150)}
                </p>
                <span className="text-xs text-gray-500 border-t-[1px] pt-2 mt-2 inline-block ">
                  <bdi className="w-[5px] h-[5px] inline  ">
                    <img
                      className=" w-full max-w-[20px] h-auto object-cover  inline ltr:mr-1 rtl:ml-1 rtl:mr-0 opacity-50"
                      src={calendarIcon}
                      alt="calendar icon"
                      width={20}
                      height={20}
                    />
                  </bdi>
                  {el.date.split('00', 1)}
                </span>
              </div>
              <div className="flex flex-wrap justify-between -mt-2">
                <div className="space-x-2">
                  <Link
                    to={`new/${el.slug}`}
                    aria-label="Share this post"
                    type="button"
                    className="p-2 text-center">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 512 512"
                      className="w-4 h-4 fill-current text-light-green">
                      <path d="M404,344a75.9,75.9,0,0,0-60.208,29.7L179.869,280.664a75.693,75.693,0,0,0,0-49.328L343.792,138.3a75.937,75.937,0,1,0-13.776-28.976L163.3,203.946a76,76,0,1,0,0,104.108l166.717,94.623A75.991,75.991,0,1,0,404,344Zm0-296a44,44,0,1,1-44,44A44.049,44.049,0,0,1,404,48ZM108,300a44,44,0,1,1,44-44A44.049,44.049,0,0,1,108,300ZM404,464a44,44,0,1,1,44-44A44.049,44.049,0,0,1,404,464Z"></path>
                    </svg>
                  </Link>
                </div>
                <Link to={`/new/${el.slug}`}>
                  <Button1 txt={Tr('global.btnPost')} />
                </Link>
              </div>
            </SwiperSlide>
          ))) || (
          <SwiperSlide className="!w-[unset]">
            {/* <Skeleton
              baseColor={'#8ff1d7'}
              className="!w-[unset] !min-w-[250px] !max-w-[300px] !mx-5"
              width={300}
              height={300}
              count={3}
              inline
            /> */}
            <div className="bg-light-green/50 max-w-max px-4 py-8 rounded-lg drop-shadow-lg my-3 shadow-light-green">
              <Skeleton
                baseColor={'#8ff1d7'}
                width={300}
                height={200}
                count={1}
              />
              <Skeleton
                baseColor={'#8ff1d7'}
                width={100}
                height={35}
                count={1}
              />
              <Skeleton
                baseColor={'#8ff1d7'}
                width={300}
                height={20}
                count={2}
              />
            </div>
          </SwiperSlide>
        )}
      </Swiper>
    </div>
  );
};
export default Card_Content_1;
