const Button_1 = ({ txt, css }) => {
  return (
    <button
      className={`text-white bg-light-green outline-none border-0 py-2 px-6 focus:outline-none hover:bg-light-green rounded text-lg ${css}`}>
      {txt}
    </button>
  );
};

export default Button_1;
