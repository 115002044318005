import { useState } from 'react';
import Link1 from './ui/Link_1';
const Nav_1 = ({ content }) => {
  const [activeId, setActiveId] = useState();

  return (
    <div className="container pt-28">
      <nav className="rounded-md w-full">
        <ol className="flex">
          <li>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              className="w-5 h-5 pr-1 ">
              <path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"></path>
            </svg>
          </li>
          {content &&
            content.map((el, idx) => (
              <Link1
                setActive={() => setActiveId(idx)}
                active={activeId === idx ? 'active-ol' : ''}
                link={el.page_url}
                tip={el.name}
                key={idx}
                section={el.name}
              />
            ))}
        </ol>
      </nav>
    </div>
  );
};

export default Nav_1;
