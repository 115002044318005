import {
  useDispatch,
  useSelector,
  useEffect,
  loadMainsAsync,
} from './../../../utils/Redux.Elements';

import {
  Main1,
  OurServices1,
  AboutUs1,
  HospitalMajor1,
  Why1,
  BestDoctors1,
  LastNews1,
  TrustBy1,
  Visitors1,
  Maps1,
} from './IC.Index';

const Index_1 = () => {
  const dispatch = useDispatch();
  const { mains } = useSelector((state) => state.mains);
  useEffect(() => {
    dispatch(loadMainsAsync);
  }, [dispatch]);

  return (
    <>
      <Main1
        content={(mains && mains.settings.slider) || ''}
        contactInfo={(mains && mains.contact_infos) || ''}
      />
      <OurServices1
        content={(mains && mains.settings.services) || ''}
        contentCard={(mains && mains.services) || ''}
      />
      <HospitalMajor1
        content={(mains && mains.settings.departments) || ''}
        contentCard={(mains && mains.departments) || ''}
      />
      <AboutUs1 content={(mains && mains.settings.about_us) || ''} />
      <Why1 />
      <BestDoctors1 contentCard={(mains && mains.doctors) || ''} />
      <LastNews1
        content={(mains && mains.settings.posts) || ''}
        contentCard={(mains && mains.posts) || ''}
      />
      <TrustBy1
        content={(mains && mains.settings.brands) || ''}
        contentCard={(mains && mains.brands) || ''}
      />
      <Visitors1
        content={(mains && mains.settings.ratings) || ''}
        contentCard={(mains && mains.ratings) || ''}
      />
      <Maps1 />
    </>
  );
};
export default Index_1;
