const Text_Area_1 = ({ placeholder, cols, rows, id, name, key }) => {
  return (
    <div className="flex flex-col my-5" key={key}>
      <label className="leading-7 text-sm text-gray-600" htmlFor={id}>
        {/* {placeholder} */}
      </label>
      <textarea
        className="w-full bg-white rounded border border-gray-300 focus:border-light-green focus:ring-2 focus:ring-light-green hover:border-light-green hover:ring-1 hover:ring-light-green h-32 text-base outline-none text-gray-700 py-1 px-3 resize-none leading-6 transition-colors duration-200 ease-in-out"
        name={name}
        id={id}
        placeholder={placeholder}
        cols={cols}
        rows={rows}></textarea>
    </div>
  );
};

export default Text_Area_1;
