import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';
import 'swiper/css/lazy';
import 'swiper/css/grid';
import 'swiper/css/pagination';
import '@/index.css';
import IconCard from '@assets/icons/injection_needle.svg';

import { Autoplay, Lazy, Grid, Pagination } from 'swiper';
import { Tr, Truncate } from '@assets/js/awds_function';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

const ContentCard1 = ({ cards }) => {
  return (
    <div className="container overflow-hidden">
      <Swiper
        dir={'ltr'}
        breakpoints={{
          640: { width: 640, slidesPerView: 1, grid: { fill: 'row', rows: 2 } },
          1024: {
            width: 1024,
            slidesPerView: 3,
            grid: { fill: 'row', rows: 2 },
          },
        }}
        grid={{ fill: 'row', rows: 2 }}
        pagination={{ clickable: true }}
        autoplay={{ delay: 2500, disableOnInteraction: false }}
        lazy={true}
        modules={[Autoplay, Lazy, Grid, Pagination]}>
        {(cards &&
          cards.map((el, idx) => (
            <SwiperSlide key={idx} className="grid justify-center pb-10 pt-24">
              <div
                id="card"
                key={idx}
                className="relative hover:scale-105  max-w-[220px]   rounded-xl shadow-md hover:shadow-xl">
                <div
                  id="doctor"
                  className="relative bg-white w-[216px]   rounded-xl h-[100px]">
                  <img
                    className="relative w-full  max-h-[290px] -top-[80px] "
                    src={el.image_path}
                    alt={`alter One`}
                    width={80}
                    height={60}
                  />
                </div>
                <div className=" relative p-5 gradient-custom max-w-[220px]   rounded-xl text-center overflow-hidden  ">
                  <img
                    className="absolute z-10 opacity-10 w-full h-auto object-cover  !max-w-[50px] "
                    width={80}
                    height={80}
                    id="injection"
                    src={IconCard}
                    alt="injection needle Icons"
                  />
                  <h6 className="font-[600] mb-2 ">{el.name}</h6>
                  <p className="text-[12px] leading-5 max-h-[50px] overflow-ellipsis">
                    {`${Tr('sections_1.best-doctors.about-doctor')} ${Truncate(
                      el.disc,
                      25
                    )}`}
                  </p>
                </div>
              </div>
            </SwiperSlide>
          ))) || (
          <div className="bg-light-green/50 max-w-max px-4 py-8 rounded-lg drop-shadow-lg my-3 shadow-light-green">
            <Skeleton
              baseColor={'#8ff1d7'}
              width={300}
              height={200}
              count={1}
            />
            <Skeleton baseColor={'#8ff1d7'} width={100} height={35} count={1} />
            <Skeleton baseColor={'#8ff1d7'} width={300} height={20} count={2} />
          </div>
        )}
      </Swiper>
    </div>
  );
};
export default ContentCard1;
