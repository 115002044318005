const Card_1 = ({ img, cardIcon, name, about }) => {
  return (
    <div className="border-[1px]  flex flex-col items-center p-8 transition-colors duration-300 transform cursor-pointer group hover:bg-light-green hover:shadow-md rounded-xl gradient-custom">
      {' '}
      <img
        className="object-cover w-32 h-32 rounded-full ring-4 ring-gray-300"
        src={
          img
            ? require(`@assets/img/${img}`)
            : require(`@assets/icons/Avatar.svg`)
        }
        width={128}
        height={128}
        alt={img.split('_', 1) + ' image'}
      />{' '}
      <h1 className="mt-4 text-2xl font-semibold text-gray-700  capitalize  ">
        {' '}
        {name}{' '}
      </h1>{' '}
      <p className="mt-2 text-gray-500  capitalize  text-center">{about}</p>{' '}
    </div>
  );
};
export default Card_1;
