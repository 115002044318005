const data = [
  {
    link: '/',
    section: 'Home ',
    i18: 'home',

    tip: 'Back to Home Page',
  },
  {
    link: '/about',
    section: 'About ',
    i18: 'about',

    tip: 'Back to About Page',
  },
  {
    link: '/contact',
    section: 'contact ',
    i18: 'contact',

    tip: 'Back to contact Page',
  },
  {
    link: '/f-and-q',
    section: 'F&Q ',
    i18: 'f_and_q',

    tip: 'Back to F&Q Page',
  },
];
module.exports = data;
