// من هنا يتم ارسال البيانات الى dispatch
// زائدا عملةيت middleware
import MainData from '../../../services/main.service';
import actions from './main.actions';

// home data

export const loadMainsAsync = () => (dispatch) => {
  dispatch(actions.mainsLoadStart());
  let local = localStorage.i18nextLng;
  MainData.getAllData(local)
    .then((res) => dispatch(actions.mainsLoadSuccess(res.data)))
    .catch((error) => dispatch(actions.mainsLoadError(error)));
};
// faq data

export const loadFaqsAsync = () => (dispatch) => {
  dispatch(actions.faqsLoadSuccess());
  let local = localStorage.i18nextLng;
  MainData.getAllFaqCats(local)
    .then((res) => dispatch(actions.faqsLoadSuccess(res.data)))
    .catch((error) => dispatch(actions.mainsLoadError(error)));
};
// post data

export const loadPostAsync = () => (dispatch) => {
  dispatch(actions.postLoadSuccess());
  // let local = localStorage.i18nextLng;
  let slug = localStorage.slug;
  MainData.getPost(slug)
    .then((res) => dispatch(actions.postLoadSuccess(res.data)))
    .catch((error) => dispatch(actions.mainsLoadError(error)));
};
