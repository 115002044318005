import { lazy } from 'react';
import Button1 from '@views/include/ui/buttons/Button_1';
import { Tr, Truncate } from '@functions/awds_function.js';
import { Link } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

const CardImg1 = lazy(() => import('./Card_Img_1'));
const Section_Card = ({ contentCard }) => {
  return (
    contentCard &&
    contentCard.map((el, idx) => (
      <div
        key={idx}
        className="hover:scale-105 flex relative items-end overflow-hidden bg-cover rounded-lg h-96 shadow-lg max-w-[350px]">
        <CardImg1 url={el.image_path} />
        <div className="w-full px-8 py-4 overflow-hidden rounded-b-lg  backdrop-blur-lg bg-light-green/60">
          {(el.title && (
            <h2 className="mt-4 text-2xl font-semibold text-white capitalize ">
              {Truncate(el.title, 20)}
            </h2>
          )) || (
            <Skeleton
              baseColor={'#8ff1d7'}
              className="!w-[200px] !h-5"
              count={1}
            />
          )}
          {(el.disc && (
            <p className="mt-2 text-sm tracking-wider text-white/75 mb-5 ">
              {Truncate(el.disc, 100)}
            </p>
          )) || (
            <Skeleton
              baseColor={'#8ff1d7'}
              className="!w-[200px] !h-5"
              count={1}
            />
          )}
          {(el.slug && (
            <Link to={`/major/${el.slug}`}>
              <Button1 txt={Tr('sections_1.hospital_major.btn')} />
            </Link>
          )) || <Skeleton baseColor={'#8ff1d7'} />}
        </div>
      </div>
    ))
  );
};
export default Section_Card;
