import { Truncate } from '@assets/js/awds_function';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

import 'react-loading-skeleton/dist/skeleton.css';

const Text_1 = ({ heading, disc }) => {
  return (
    <div className="text">
      {(heading && <h2 className="heading">{heading}</h2>) || (
        <Skeleton
          baseColor={'#8ff1d7'}
          className="!w-[200px] !self-stretch !h-[50px] mb-2"
          count={1}
        />
      )}
      {(disc && (
        <p className="w-[100%] max-w-2xl mr-10 text-paragraph-color-black rtl:mr-0 ">
          {Truncate(disc, 160)}
        </p>
      )) || (
        <Skeleton
          baseColor={'#8ff1d7'}
          height={20}
          count={2}
          className="!w-[300px] md:!w-[500px] lg:!w-[50rem] !my-2 "
        />
      )}
    </div>
  );
};
export default Text_1;
