import React from 'react';

const Message = ({ message, code }) => {
  return (
    <div
      className={`fixed top-32 duration-500 ease-in-out   ${
        message ? 'left-1 opacity-100' : '-left-52 opacity-0'
      }  z-40 mx-10 p-5 bg-white rounded-lg shadow-lg`}>
      <h1 className="text-md font-semibold mb-3 text-red-500">{code}</h1>
      <p className="text-dark-black text-sm">{message}</p>
    </div>
  );
};

export default Message;
