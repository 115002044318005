import React from 'react';
/* import Card1 from './Card_1';*/ import feckData from '@db/News';
import LeftArrow from '@assets/icons/left_arrow.svg';
import RightArrow from '@assets/icons/right_arrow.svg';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Lazy, Grid, Navigation } from 'swiper';
import { Truncate } from '@assets/js/awds_function';
import Button1 from '@views/include/ui/buttons/Button_1';
import { Dir } from '@assets/js/awds_function';
/* Import Swiper styles*/ import 'swiper/css';
import 'swiper/css/lazy';
import 'swiper/css/grid';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import '@/index.css';
import { Link } from 'react-router-dom';
const Smeller_1 = ({ contentCard }) => {
  return (
    <div className="my-20 ">
      <h4 className="text-2xl text-light-green font-[600]">Smeller New</h4>
      <Swiper
        dir={'ltr'}
        className="flex flex-col-reverse "
        slidesPerView={1}
        breakpoints={{
          200: { slidesPerView: 1, spaceBetween: 10 },
          450: { slidesPerView: 2, spaceBetween: 10 },
          768: { slidesPerView: 3, spaceBetween: 20 },
          1024: { slidesPerView: 5, spaceBetween: 40 },
        }}
        effect={'cards'}
        grid={{ fill: 'row' }}
        lazy={true}
        pagination={{ clickable: true }}
        navigation={{
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        }}
        autoplay={{ delay: 2500, disableOnInteraction: false }}
        loop={true}
        modules={[Autoplay, Lazy, Grid, Navigation]}>
        <div className="flex justify-end  items-center  !static  mt-10 gap-x-5 mb-5 ">
          <button className="  swiper-button-prev  hover:bg-hover-light-green">
            <img src={LeftArrow} alt="Left arrow icons" width={25} />
          </button>
          <button className=" rounded-md bg-light-green swiper-button-next !opacity-25 hover:!opacity-100">
            <img src={RightArrow} alt="Right arrow icons" width={25} />
          </button>
        </div>
        {/* //! Card 1 */}
        {/* {feckData.map((el, ind) => ( <SwiperSlide key={ind} id="card" className=" bg-white max-w-[365px]  hover:border-light-green shadow-sm hover:shadow-md rounded-xl overflow-hidden mb-3"> <div className="max-w-[365px] relative "> <div id="blur-layer" className="absolute top-0 left-0 w-[100%] h-[100%] flex justify-center items-center"> <img className="opacity-0" id="show-icon" src={require(`@assets/icons/${el.showIcon}`)} alt={`${el.showIcon.split('.', 1)} icon`} /> </div> <img width="365" height="335" src={require(`@assets/img/${el.postImage}`)} alt={`${el.postImage.split('.', 1)} icon`} /> </div> <div className="mt-5 p-5 flex justify-start flex-col "> <h6 id="title" className="font-[600] text-2xl mb-4"> {Truncate(el.postTitle, 18)} </h6> <p id="text" className="text-paragraph-color mb-5 text-justify"> {Truncate(el.postDesc, 100)} </p> <p className="text-xs border-t-[1px] pt-2"> {`${new Date().getFullYear()} - ${new Date().getMonth()} - ${new Date().getDay()}`} </p> <Link to={`/post/${ind + 1}`} className={'self-end'}> <Button1 txt="more" /> </Link> </div> </SwiperSlide> ))} */}
        {/* //! Card 2 */}
        {/* {feckData.map((el, ind) => ( <SwiperSlide className="max-w-xs rounded-md shadow-md bg-white mb-10 "> <img src={require(`@assets/img/${el.postImage}`)} alt="" className="object-cover object-center w-full rounded-t-md h-72 " /> <div className="flex flex-col justify-between p-6 space-y-8"> <div className="space-y-2"> <h2 className="text-3xl font-semibold tracking-wide"> Donec lectus leo </h2> <p> Curabitur luctus erat nunc, sed ullamcorper erat vestibulum eget. </p> </div> <button type="button" className="flex items-center justify-center w-full p-3 font-semibold tracking-wide rounded-md bg-light-green text-white "> Read more </button> </div> </SwiperSlide> ))} */}
        {/* //! Card 3 */}
        {contentCard &&
          contentCard.map((el, idx) => (
            <SwiperSlide
              key={idx}
              className="flex flex-col max-w-[350px] p-2 space-y-6 overflow-hidden rounded-lg shadow-md bg-white mb-24 ">
              <div>
                <img
                  src={el.image_path}
                  alt={`${el.image_path} img`}
                  className="object-cover w-full mb-4 h-60 sm:h-52  "
                />
                <h2 className="mb-1 text-lg font-semibold">
                  {Truncate(el.title, 50)}
                </h2>
                <p className="text-xs text-gray-600">
                  {Truncate(el.desc, 150)}
                </p>
              </div>
              <div className="flex flex-wrap justify-between">
                <div className="space-x-2">
                  <Link
                    to={`/new/${el.slug}`}
                    aria-label="Share this post"
                    type="button"
                    className="p-2 text-center">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 512 512"
                      className="w-4 h-4 fill-current text-light-green">
                      <path d="M404,344a75.9,75.9,0,0,0-60.208,29.7L179.869,280.664a75.693,75.693,0,0,0,0-49.328L343.792,138.3a75.937,75.937,0,1,0-13.776-28.976L163.3,203.946a76,76,0,1,0,0,104.108l166.717,94.623A75.991,75.991,0,1,0,404,344Zm0-296a44,44,0,1,1-44,44A44.049,44.049,0,0,1,404,48ZM108,300a44,44,0,1,1,44-44A44.049,44.049,0,0,1,108,300ZM404,464a44,44,0,1,1,44-44A44.049,44.049,0,0,1,404,464Z"></path>
                    </svg>
                  </Link>
                </div>
                {/*send id to router by ind */}
                <Link to={`/new/${el.slug}`}>
                  <Button1 txt={'more'} css={'text-xs'} />
                </Link>
              </div>
            </SwiperSlide>
          ))}
      </Swiper>
    </div>
  );
};
export default Smeller_1;
