//TODO ! <   : Set Video Element -->

const Media_1 = () => {
  return (
    <img
      src={require('@assets/img/video_2.webp')}
      alt="Media Content"
      width="520"
      height="570"
    />
  );
};
export default Media_1;
