import { useState, useEffect } from 'react';

const Scroll = () => {
  const [scrollPosition, setScrollPosition] = useState(0);

  const handleScroll = () => {
    const winScroll = document.documentElement.scrollTop;
    const height =
      document.documentElement.scrollHeight -
      document.documentElement.clientHeight;
    const scrolled = (winScroll / height) * 100;
    setScrollPosition(scrolled);
  };
  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const styleScroll = {
    width: `${scrollPosition}%`,
  };
  return (
    <div className={`sticky top-0 left-0 h-2  z-50 w-[100%] !transition-none`}>
      <div
        style={styleScroll}
        className={`bg-light-green h-full z-50 !duration-[0s]`}></div>
    </div>
  );
};

export default Scroll;
