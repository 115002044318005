import { lazy } from 'react';
import { Truncate } from '@assets/js/awds_function';
const Icon1 = lazy(() => import('./Icon_1'));

const Card_Server_1 = ({ contentCard }) => {
  return (
    <>
      {contentCard &&
        contentCard.map((el, idx) => (
          <div
            key={idx}
            className="card px-16 py-10 shadow-md max-w-[400px] border-white hover:border-light-green hover:scale-105 border-2 gradient-custom bg-white">
            <div className="flex flex-col items-center ">
              <div className="mb-5 rounded-full overflow-hidden w-28 h-28 p-4 ">
                <Icon1 el={el.icon_path} />
              </div>
              <h6 className="font-[600] text-lg   ">{el.title}</h6>
              <p className="text-xs text-center my-3 leading-5 ">
                {Truncate(el.disc, 80)}
              </p>
            </div>
          </div>
        ))}
    </>
  );
};
export default Card_Server_1;
