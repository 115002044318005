import { Link } from 'react-router-dom';
import { Tr } from '@assets/js/awds_function';
import fakeData from '@db/Navs';
const Sections_Links_1 = () => {
  return (
    <div className="col-span-3 lg:col-span-1 order-2 lg:order-none  ">
      <h2 className="border-b-2 max-w-[200px] pb-2 mb-2">Section Link</h2>
      <ul id={'ulContainer'} className="nav-links">
        {fakeData.map((el, ind) => (
          <li key={ind}>
            <Link to={el.link}>{Tr('header.nav.' + el.i18)}</Link>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Sections_Links_1;
