import { useTranslation } from 'react-i18next';
import translateIcon from '@assets/icons/translateIcon.svg';
import MainData from './../../../../../services/main.service';
import {
  useDispatch,
  loadMainsAsync,
  loadFaqsAsync,
  useEffect,
} from './../../../../../utils/Redux.Elements';

let Lang = 'en';

const Translate_btn_1 = () => {
  const [t, i18n] = useTranslation();
  const handelLang = () => {
    Lang === 'en' ? (Lang = 'ar') : (Lang = 'en');
    i18n.changeLanguage(Lang);
    Lang === 'en' ? (document.body.dir = 'ltr') : (document.body.dir = 'rtl');
    let langs = localStorage.i18nextLng;
    MainData.getAllData(langs);
    dispatch(loadMainsAsync(), loadFaqsAsync());
  };

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadMainsAsync());
  }, [dispatch]);
  return (
    <button
      onClick={handelLang}
      className="flex w-16 h-16  items-center justify-center ltr:lg:gap-x-2  ltr:font-AR rtl:font-EN opacity-50 hover:opacity-100 m-0 rtl:lg:ml-5">
      <img
        src={translateIcon}
        className="object-cover"
        alt="translate Icon"
        width={16}
        height={16}
      />
      <span className="font-[400] hidden md:inline-block">
        {t('header.btnLang')}
      </span>
    </button>
  );
};

export default Translate_btn_1;
