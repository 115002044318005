// هنا تجي البيانات وتتخزن من
// dispatch
import actionTypes from './main.actionTypes';

const mainsLoadStart = () => ({
  type: actionTypes.MAIN_LOAD_START,
});
const mainsLoadSuccess = (mains) => ({
  type: actionTypes.MAIN_LOAD_SUCCESS,
  payload: mains,
});
const faqsLoadSuccess = (faqs) => ({
  type: actionTypes.FAQ_LOAD_SUCCESS,
  payload: faqs,
});
const postLoadSuccess = (post) => ({
  type: actionTypes.POST_LOAD_SUCCESS,
  payload: post,
});
const mainsLoadError = (errorMessage) => ({
  type: actionTypes.MAIN_LOAD_ERROR,
  payload: errorMessage,
});

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  mainsLoadStart,
  mainsLoadSuccess,
  faqsLoadSuccess,
  postLoadSuccess,
  mainsLoadError,
};
