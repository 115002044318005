const date = [
  {
    img: 'Doctor_1.webp',
    cardIcon: 'injection_needle.svg',
    name: 'Dr Ahmed Ali',
    about:
      'The Departments NameThe DepartmentsName TheDepartments NameThe Departments Name The Departments Name',
  },
  {
    img: 'Doctor_2.webp',
    cardIcon: 'injection_needle.svg',
    name: 'Dr Noor Ahmed',
    about:
      'The Departments NameThe DepartmentsName TheDepartments NameThe Departments Name The Departments Name',
  },
  {
    img: 'Doctor_3.webp',
    cardIcon: 'injection_needle.svg',
    name: 'Dr Mohamed Omar',
    about:
      'The Departments NameThe DepartmentsName TheDepartments NameThe Departments Name The Departments Name',
  },
  {
    img: 'Doctor_1.webp',
    cardIcon: 'injection_needle.svg',
    name: 'Dr Abdullah Nasre',
    about:
      'The Departments NameThe DepartmentsName TheDepartments NameThe Departments Name The Departments Name',
  },
  {
    img: 'Doctor_4.webp',
    cardIcon: 'injection_needle.svg',
    name: 'Dr Abdullah Nasre',
    about:
      'The Departments NameThe DepartmentsName TheDepartments NameThe Departments Name The Departments Name',
  },
  {
    img: 'Doctor_4.webp',
    cardIcon: 'injection_needle.svg',
    name: 'Dr Abdullah Nasre',
    about:
      'The Departments NameThe DepartmentsName TheDepartments NameThe Departments Name The Departments Name',
  },
  {
    img: 'Doctor_4.webp',
    cardIcon: 'injection_needle.svg',
    name: 'Dr Abdullah Nasre',
    about:
      'The Departments NameThe DepartmentsName TheDepartments NameThe Departments Name The Departments Name',
  },
  {
    img: 'Doctor_4.webp',
    cardIcon: 'injection_needle.svg',
    name: 'Dr Abdullah Nasre',
    about:
      'The Departments NameThe DepartmentsName TheDepartments NameThe Departments Name The Departments Name',
  },
  {
    img: 'Doctor_4.webp',
    cardIcon: 'injection_needle.svg',
    name: 'Dr Abdullah Nasre',
    about:
      'The Departments NameThe DepartmentsName TheDepartments NameThe Departments Name The Departments Name',
  },
  {
    img: 'Doctor_4.webp',
    cardIcon: 'injection_needle.svg',
    name: 'Dr Abdullah Nasre',
    about:
      'The Departments NameThe DepartmentsName TheDepartments NameThe Departments Name The Departments Name',
  },
  {
    img: 'Doctor_4.webp',
    cardIcon: 'injection_needle.svg',
    name: 'Dr Abdullah Nasre',
    about:
      'The Departments NameThe DepartmentsName TheDepartments NameThe Departments Name The Departments Name',
  },
];
module.exports = date;
