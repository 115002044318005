import { Tr } from '@assets/js/awds_function';
import { Link } from 'react-router-dom';

const Maps_1 = () => {
  return (
    <section className="text-gray-600 body-font relative">
      <div className="container px-5  mx-auto lg:my-24  flex sm:flex-nowrap flex-wrap">
        <div className=" shadow-lg lg:w-full md:w-2/2 bg-gray-300 rounded-lg overflow-hidden sm:mr-10 p-10 flex items-end justify-start relative min-h-[600px]">
          <iframe
            className="border-0 w-[100%] h-[100%] grayscale-[1] contrast-[1.2] opacity-[.4] absolute inset-0"
            frameBorder="0"
            title="map"
            marginHeight="0"
            marginWidth="0"
            scrolling="no"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3846.1676500727626!2d44.189130615265505!3d15.421500260564967!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1603d91f7c0191eb%3A0x45bdb18a47614e4f!2sAmran%20Roundabout!5e0!3m2!1sen!2s!4v1665407822753!5m2!1sen!2s"
            width="600"
            height="450"
            allowFullScreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"></iframe>
          <div className="bg-white relative flex flex-wrap py-6 rounded shadow-md">
            <div className="lg:w-1/2 px-6">
              <h2 className="title-font font-semibold text-gray-900  text-xs">
                {Tr('sections_1.contact-us.address-label')}
              </h2>
              <p className="mt-1"> {Tr('sections_1.contact-us.address')}</p>
            </div>
            <div className="lg:w-1/2 px-6 mt-4 lg:mt-0">
              <h2 className="title-font font-semibold text-gray-900  text-xs">
                {Tr('sections_1.contact-us.email')}
              </h2>
              <Link to="/" className="text-light-green leading-relaxed">
                example@email.com
              </Link>
              <h2 className="title-font font-semibold text-gray-900  text-xs mt-4">
                {Tr('sections_1.contact-us.phone')}
              </h2>
              <p className="leading-relaxed">+967 777 777 771</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default Maps_1;
