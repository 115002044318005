import { Tr } from '@assets/js/awds_function';
const text_1 = () => {
  return (
    <div className="relative mb-4">
      <label htmlFor="message" className="leading-7 text-sm text-gray-600">
        {/* {Tr('sections_1.contact-us.text-area-message')} */}
      </label>
      <textarea
        id="message"
        name="message"
        placeholder={Tr('sections_1.contact-us.text-area-message')}
        className="w-full bg-white rounded border border-gray-300 focus:border-light-green focus:ring-2 focus:ring-light-green h-32 hover:border-light-green hover:ring-1 hover:ring-light-green text-base outline-none text-gray-700 py-1 px-3 resize-none leading-6 transition-colors duration-200 ease-in-out"></textarea>
    </div>
  );
};
export default text_1;
