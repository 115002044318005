import { lazy } from 'react';
import { Tr } from '@assets/js/awds_function';
import { Button1, Text1, ContentCard1 } from './IC.Our_Services';
const Bg1 = lazy(() => import('./ui/bg/Bg_1'));

const Our_Services_1 = ({ content, contentCard }) => {
  return (
    <section className="relative our-services">
      <br className="border-none" id="our-service" />
      {content.image && <Bg1 url={content.image} />}
      <div className="container flex-col py-20 ">
        <div className="flex  flex-col gap-y-5 md:flex-row ">
          <Text1
            heading={content && content.title}
            disc={content && content.subtitle}
          />

          <Button1 txt={Tr('global.btnMore')} css={'mx-5 self-end'} />
        </div>
        <div className="mt-20 flex flex-col items-center  gap-y-5 md:gap-x-5 lg:flex-row">
          <ContentCard1 contentCard={contentCard && contentCard} />
        </div>
      </div>
    </section>
  );
};
export default Our_Services_1;
