import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

const Trust_By_1 = ({ content, contentCard }) => {
  return (
    /* <section> <div className="bg-white  p-5 "> <div className="flex flex-col gap-y-5 overflow-hidden"> <div className=" flex gap-x-10 "> {img2.map((el, index) => ( <img key={index} src={require(`@assets/Brands/${el.src}`)} alt="1 brand" /> ))} {img.map((el, index) => ( <img key={index} src={require(`@assets/Brands/${el.src}`)} alt="1 brand" /> ))} </div> <div className=" flex gap-x-10 "> {img2.map((el, index) => ( <img key={index} src={require(`@assets/Brands/${el.src}`)} alt="1 brand" /> ))} {img.map((el, index) => ( <img key={index} src={require(`@assets/Brands/${el.src}`)} alt="1 brand" /> ))} </div> </div> </div> </section>*/ <section className="p-6  container">
      <div className=" p-4 mx-auto text-center text-light-green lg:mb-10 relative before:absolute before:w-[10%] before:bg-light-green before:bottom-0 before:h-1 before:rounded-3xl before:right-[50%] before:translate-x-[50%]  ">
        {(content && (
          <h2 className="text-4xl font-bold mb-5">{content.title}</h2>
        )) || (
          <Skeleton
            baseColor={'#8ff1d7'}
            className="!w-[200px] !h-10"
            count={1}
            inline
            style={{ margin: '.5rem' }}
          />
        )}
      </div>
      <div className="flex flex-col items-center md:flex-row flex-wrap justify-center px-10 ">
        {(contentCard &&
          contentCard.map((el, idx) => (
            <div
              key={idx}
              className="flex justify-center w-2/2 p-6 align-middle md:w-1/3 xl:w-1/4 xl:max-w-[150px]  grayscale-[1] hover:grayscale-0">
              <img
                className="object-cover w-full h-auto max-w-10 "
                src={el.image_path}
                alt={el.image}
                width={60}
                height={20}
              />
            </div>
          ))) || (
          <>
            <Skeleton
              baseColor={'#8ff1d7'}
              className="!w-[200px] !h-20"
              count={1}
              inline
              style={{ margin: '.5rem' }}
            />
            <Skeleton
              baseColor={'#8ff1d7'}
              className="!w-[200px] !h-20"
              count={1}
              inline
              style={{ margin: '.5rem' }}
            />
            <Skeleton
              baseColor={'#8ff1d7'}
              className="!w-[200px] !h-20"
              count={1}
              inline
              style={{ margin: '.5rem' }}
            />
          </>
        )}
      </div>
    </section>
  );
};
export default Trust_By_1;
