import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
/* Import Translation Files */ /* Example*/ import TranslationAR from './libs/i18n/Locales/ar.json';
import TranslationEN from './libs/i18n/Locales/en.json';
const resources = {
  en: { translation: TranslationEN },
  ar: { translation: TranslationAR },
};
i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    lng: 'en',
    interpolation: { escapeValue: false },
    react: { useSuspense: false },
  });
export default i18n;
