const data = [
  {
    postTitle: '1 - New Title New Title New Title',
    postImage: 'card_img_1.webp',
    showIcon: 'eye.svg',
    postDesc:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been industry's standarddummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to makea type specimenbook.It has",
    moreImage: [{ url: 'card_img_1.webp' }, { url: 'card_img_3.webp' }],
  },
  {
    postTitle: '2 - New Title New Title New Title',
    postImage: 'card_img_1.webp',
    showIcon: 'eye.svg',
    postDesc:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been industry's standarddummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to makea type specimenbook.It has",
    moreImage: [
      { url: 'card_img_1.webp' },
      { url: 'card_img_2.webp' },
      { url: 'card_img_3.webp' },
    ],
  },
  {
    postTitle: '3 - New Title New Title New Title',
    postImage: 'card_img_1.webp',
    showIcon: 'eye.svg',
    postDesc:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been industry's standarddummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to makea type specimenbook.It has",
    moreImage: [],
  },
  {
    postTitle: '4 - New Title New Title New Title',
    postImage: 'card_img_1.webp',
    showIcon: 'eye.svg',
    postDesc:
      "4- Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been industry's standarddummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to makea type specimenbook.It has",
    moreImage: [],
  },
  {
    postTitle: '5 - New Title New Title New Title',
    postImage: 'card_img_1.webp',
    showIcon: 'eye.svg',
    postDesc:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been industry's standarddummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to makea type specimenbook.It has",

    moreImage: [{ url: 'card_img_1.webp' }],
  },
  {
    postTitle: '6 - New Title New Title New Title',
    postImage: 'card_img_1.webp',
    showIcon: 'eye.svg',
    postDesc:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been industry's standarddummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to makea type specimenbook.It has",

    moreImage: [{ url: 'card_img_2.webp' }, { url: 'card_img_3.webp' }],
  },
  {
    postTitle: '7 - New Title New Title New Title',
    postImage: 'card_img_1.webp',
    showIcon: 'eye.svg',
    postDesc:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been industry's standarddummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to makea type specimenbook.It has",

    moreImage: [{ url: 'card_img_1.webp' }, { url: 'card_img_3.webp' }],
  },
  {
    postTitle: '8 - New Title New Title New Title',
    postImage: 'card_img_1.webp',
    showIcon: 'eye.svg',
    postDesc:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been industry's standarddummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to makea type specimenbook.It has",

    moreImage: [
      { url: 'card_img_1.webp' },
      { url: 'card_img_2.webp' },
      { url: 'card_img_3.webp' },
    ],
  },
  {
    postTitle: '9 - New Title New Title New Title',
    postImage: 'card_img_1.webp',
    showIcon: 'eye.svg',
    postDesc:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been industry's standarddummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to makea type specimenbook.It has",

    moreImage: [
      { url: 'card_img_1.webp' },
      { url: 'card_img_2.webp' },
      { url: 'card_img_3.webp' },
    ],
  },
  {
    postTitle: '10 - New Title New Title New Title',
    postImage: 'card_img_2.webp',
    showIcon: 'eye.svg',
    postDesc:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been industry's standarddummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to makea type specimenbook.It has",

    moreImage: [
      { url: 'card_img_1.webp' },
      { url: 'card_img_2.webp' },
      { url: 'card_img_3.webp' },
    ],
  },
  {
    postTitle: '11 - New Title New Title New Title',
    postImage: 'card_img_3.webp',
    showIcon: 'eye.svg',
    postDesc:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been industry's standarddummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to makea type specimenbook.It has",

    moreImage: [
      { url: 'card_img_1.webp' },
      { url: 'card_img_2.webp' },
      { url: 'card_img_3.webp' },
    ],
  },
];
module.exports = data;
