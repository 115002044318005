// هنا يتم ارسال اسم الاكشين الي بيروح لل dispatch

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  MAIN_LOAD_START: 'MAIN_LOAD_START',
  MAIN_LOAD_SUCCESS: 'MAIN_LOAD_SUCCESS',
  FAQ_LOAD_SUCCESS: 'FAQ_LOAD_SUCCESS',
  POST_LOAD_SUCCESS: 'POST_LOAD_SUCCESS',
  MAIN_LOAD_ERROR: 'MAIN_LOAD_ERROR',
};
