import { ClassToGroups, Truncate } from '@assets/js/awds_function';

const Side_Image_1 = ({ heading, url }) => {
  ClassToGroups('abdullah', '.z');
  return (
    <div>
      <h1 className="text-2xl sm:text-3xl font-[600] text-light-green mb-5 leading-relaxed inline-block lg:hidden">
        {Truncate(heading, 60)}
      </h1>
      <img
        className="z mb-8 lg:mb-0 object-cover w-full h-auto 2xl:max-w-[1000px] rtl:transform-rotate-y"
        src={`https://m-t-api.mediamaxtv.com/images/sections/${url}`}
        alt="Doctor "
        width="900"
        height="1000"
      />
    </div>
  );
};
export default Side_Image_1;
