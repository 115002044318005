const { useTranslation } = require('react-i18next');

export const ClassToGroups = (Class = '', ...elements) => {
  /* function add for using at window is load */
  const add = () => {
    /* Set All Elements [ ...elements ] At Variable */
    const allElements = document.querySelectorAll(`${elements}`);
    /* Loop At Elements To Set Target Class [ Class ] */
    allElements.forEach((element) => {
      /* Set Target Class [ Class ] At Elements [ element ] */
      element.classList.add(`${Class}`);
    });
  };
  /* Run Add Function When The Window Is Load */
  window.addEventListener('load', add);
};

export const Tr = (path) => {
  const [t] = useTranslation();
  return path ? t(`${path}`) : 'not-site-path';
};
export const Dir = () => {
  return document.body.dir === 'rtl' ? 'rtl' : 'ltr';
};

export const Truncate = (txt, NoChar) => {
  if (txt) {
    if (txt.length > NoChar) {
      return txt.substring(0, NoChar) + '...';
    }
    return txt;
  } else return 'please sit your string';
};
