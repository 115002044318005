import { useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { FreeMode, Autoplay } from 'swiper';
import { Dir } from '@assets/js/awds_function';
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/pagination';
const Slider_team_1 = () => {
  const [data] = useState([
    { img: 'doc_1.png' },
    { img: 'doc_2.png' },
    { img: 'doc_3.png' },
    { img: 'doc_4.png' },
    { img: 'doc_5.png' },
  ]);
  return (
    <Swiper
      dir={'ltr'}
      slidesPerView={5}
      spaceBetween={0}
      freeMode={true}
      loop={true}
      autoplay={{ delay: 2500, disableOnInteraction: false }}
      modules={[Autoplay, FreeMode]}
      className="mySwiper rounded-3xl  ">
      {data.map((el, ind) => (
        <SwiperSlide
          key={ind}
          className="border-[1px] duration-500  hover:rounded-xl hover:scale-95  border-white overflow-hidden ">
          <img
            src={require(`@assets/img/${el.img}`)}
            className="w-full hover:scale-110 "
            alt="doc  1"
          />
        </SwiperSlide>
      ))}
    </Swiper>
  );
};
export default Slider_team_1;
