import Text1 from './ui/Text_1';
import Media1 from './ui/Media_1';
const Why_1 = () => {
  return (
    <section className="relative our-services ">
      {' '}
      <img
        className="absolute rotate-[10deg] -z-10 -left-28 rtl:left-auto rtl:-right-64  rtl:transform-rotate-y   "
        src={require('@assets/img/bg-3.webp')}
        alt="bg-3"
      />{' '}
      <div className="container flex flex-col lg:flex-row lg:justify-between items-center lg:items-start py-20 ">
        {' '}
        <div className="hover:scale-105 cursor-pointer">
          {' '}
          <Media1 />{' '}
        </div>{' '}
        <div className="lg:max-w-[50%]  flex flex-col justify-between  ">
          {' '}
          <Text1 />{' '}
        </div>{' '}
      </div>{' '}
    </section>
  );
};
export default Why_1;
