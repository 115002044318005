import { useState } from 'react';
import { Link } from 'react-router-dom';
import Logo from '@assets/img/thumb.webp';
import ProfileDropDown from './uiNew/ProfileDropDown_1';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { Tr } from '@assets/js/awds_function';

import InputSearch1 from './uiNew/InputSearch_1.jsx';

const Header_1 = ({ content }) => {
  const [load] = useState(false);
  const [activeId, setActiveId] = useState('Home');
  const [menuState, setMenuState] = useState(false);

  return (
    <header className="bg-white  border-b fixed w-full z-50 ">
      <nav className=" container">
        <div
          className={`sticky flex items-center py-3 px-4 lg:max-w-screen-xl`}>
          <div className="flex-none lg:flex-initial">
            {load ? (
              <Link to="/" className="w-14 h-14 inline-block">
                <img
                  className="object-cover h-full w-full"
                  src={Logo}
                  alt="Brand UI logo"
                />
              </Link>
            ) : (
              <Skeleton baseColor={'#8ff1d7'} width={55} height={55} />
            )}
          </div>
          <div className="flex-1 flex items-center justify-between">
            <div
              className={`bg-white absolute uppercase text-sm z-20 w-full top-16 left-0 p-4 border-b lg:static lg:block lg:border-none  ${
                menuState ? '' : 'hidden'
              }`}>
              <ul className="mt-12 space-y-5 lg:flex  lg:space-y-0 lg:mt-0 justify-center lg:gap-x-5">
                <li
                  onClick={() => setActiveId('Home')}
                  className={`text-gray-400 border-b-2 border-b-transparent hover:text-light-green ${
                    activeId === 'Home' ? 'active-li' : ''
                  }`}>
                  <Link onClick={() => window.scrollTo(0, 0)} to={'/'}>
                    {Tr(`header.nav.home`)}
                  </Link>
                </li>

                {(content &&
                  content.map((el, idx) => (
                    <li
                      key={idx}
                      onClick={() => setActiveId(idx)}
                      className={`text-gray-400 border-b-2 border-b-transparent hover:text-light-green ${
                        activeId === idx ? 'active-li' : ''
                      }`}>
                      <Link
                        onClick={() => window.scrollTo(0, 0)}
                        to={el.page_url}>
                        {/* {t(`header.nav.${el.i18}`)} */}
                        {el.name}
                      </Link>
                    </li>
                  ))) || (
                  <Skeleton
                    baseColor={'#8ff1d7'}
                    width={50}
                    height={25}
                    count={3}
                    inline
                    className="!mx-2"
                  />
                )}
              </ul>
              {/* //! this menu for small screen */}
              <ProfileDropDown class_p="mt-5 pt-5 border-t lg:hidden" />
            </div>
            <div className="flex-1 flex items-center justify-end space-x-2">
              <InputSearch1 />
              {/* //! this menu for large screen */}
              <ProfileDropDown class_p="hidden lg:block" />
              <button
                className="outline-none text-gray-400 block lg:hidden"
                onClick={() => setMenuState(!menuState)}>
                {menuState ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor">
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor">
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M4 6h16M4 12h16m-7 6h7"
                    />
                  </svg>
                )}
              </button>
            </div>
          </div>
        </div>
      </nav>
    </header>
  );
};

export default Header_1;
