import ContentCard1 from './ui/Content_Card_1';
import { Tr } from '@functions/awds_function.js';
const Best_Doctors_1 = ({ contentCard }) => {
  return (
    <section>
      <div className="container">
        <h2 className="heading">{Tr('sections_1.best-doctors.heading')}</h2>
      </div>

      <ContentCard1 cards={(contentCard && contentCard) || ''} />
    </section>
  );
};
export default Best_Doctors_1;
