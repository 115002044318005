import { lazy } from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

import 'react-loading-skeleton/dist/skeleton.css';
import { Truncate } from '@assets/js/awds_function';
const SocialIcons1 = lazy(() => import('@views/include/ui/Social_Icons_1'));

const Side_Text_1 = ({ heading, disc, contactInfo }) => {
  return (
    <div className=" col-span-1 order-2 lg:order-1 lg:self-center">
      <div className=" w-[100%]">
        {(heading && (
          <h1
            className={`text-4xl font-[600] hidden text-light-green lg:inline-block mb-5 leading-relaxed`}>
            {Truncate(heading, 50)}
          </h1>
        )) || (
          <Skeleton
            baseColor={'#8ff1d7'}
            className="!mb-5  !h-8 !w-[70%]"
            count={2}
          />
        )}
        {(disc && (
          <p className="text-paragraph-color leading-8 mb-10 text-sm lg:w-[130%] text-justify">
            {Truncate(disc, 500)}
          </p>
        )) || (
          <Skeleton baseColor={'#8ff1d7'} className={'!h-4 !mb-4'} count={3} />
        )}
        <div>
          <SocialIcons1 contactInfo={(contactInfo && contactInfo) || ''} />
        </div>
      </div>
    </div>
  );
};
export default Side_Text_1;
