const major = () => {
  return (
    <section>
      <div className="mx-auto max-w-screen-2xl px-4  sm:px-6 lg:px-8">
        <div className="grid grid-cols-1 lg:h-screen lg:grid-cols-2">
          <div className="relative z-10 lg:py-16">
            <div className="relative h-64 sm:h-80 lg:h-full lg:max-h-[800px] rounded-2xl overflow-hidden">
              <img
                alt="House"
                src={require('./../../../assets/img/card_img_1.webp')}
                className="absolute inset-0 h-full w-full  object-cover"
              />
            </div>
          </div>
          <div className="relative flex lg:max-h-[950px] items-center bg-light-green rounded-tr-2xl rounded-br-2xl rtl:rounded-tr-none rtl:rounded-br-none rtl:rounded-tl-2xl rtl:rounded-bl-2xl">
            <span className="hidden lg:absolute lg:inset-y-0 lg:-left-16 rtl:lg:left-0 rtl:lg:-right-16 lg:block lg:w-16 lg:bg-light-green  rounded-tl-2xl rounded-bl-2xl rtl:rounded-tl-none rtl:rounded-bl-none rtl:rounded-tr-2xl rtl:rounded-br-2xl "></span>
            <div className="p-8 sm:p-16 lg:p-24">
              <h2 className="text-2xl font-bold sm:text-3xl md:text-4xl text-white border-white ltr:border-l-4 ltr:pl-2 rtl:border-l-0 rtl:border-r-4 rlt:pl-0  rtl:pl-0 rtl:pr-4 ">
                Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                Tempore, debitis.
              </h2>
              <p className="mt-4 text-gray-600">
                Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                Aliquid, molestiae! Quidem est esse numquam odio deleniti,
                beatae, magni dolores provident quaerat totam eos, aperiam
                architecto eius quis quibusdam fugiat dicta.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default major;
