import React from 'react';

const InternalPage = () => {
  return (
    <section className="">
      <div className="container px-6 py-10 mx-auto">
        <h1 className="text-4xl mt-24 font-semibold  text-light-green capitalize lg:text-4xl ">
          Internal Page
        </h1>
        <div>
          <span className="w-20 h-1 bg-light-green inline-block rounded-3xl "></span>
        </div>
        <p className="max-w-2xl  my-6  text-gray-500 ">
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Illo incidunt
          ex placeat modi magni quia error alias, adipisci rem similique, at
          omnis eligendi optio eos harum.
        </p>
        <div id="internal-page"></div>
      </div>
    </section>
  );
};

export default InternalPage;
