import Card1 from './ui/Card_1';
import fakeData from './../../../db/BestDoctors';
import SliderTeam1 from './ui/Slider_team_1';
import Social1 from '../../include/ui/SocialBar_1.jsx/Social_1';
/* import fetchData from '../../../utils/fetchData'; const apiData = fetchData('https://jsonplaceholder.typicode.com/photos');*/ const About_1 =
  () => {
    /* const fakeData = apiData.read();*/ return (
      <section className="">
        <div className="container px-6 py-10 mx-auto">
          <h1 className="text-3xl mt-24 font-semibold text-center text-light-green capitalize lg:text-4xl ">
            About Us
          </h1>
          <div className="text-center">
            <span className="w-20 h-1 bg-light-green inline-block rounded-3xl "></span>
          </div>
          <p className="max-w-2xl mx-auto my-6 text-center text-gray-500 ">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Illo
            incidunt ex placeat modi magni quia error alias, adipisci rem
            similique, at omnis eligendi optio eos harum.
          </p>
          <div className="grid grid-cols-1 gap-8 mt-8 xl:mt-16 md:grid-cols-2 xl:grid-cols-4">
            {fakeData.map((el, ind) => (
              <Card1 img={el.img} name={el.name} about={el.about} key={ind} />
            ))}
          </div>
          <p className="max-w-2xl mx-auto my-24 text-center text-gray-500 ">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Illo
            incidunt ex placeat modi magni quia error alias, adipisci rem
            similique, at omnis eligendi optio eos harum.
          </p>
          <SliderTeam1 />
          <Social1 />
        </div>
      </section>
    );
  };
export default About_1;
