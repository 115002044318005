import { useEffect, useRef, useState } from 'react';
import TopIcon from '@assets/icons/top.svg';

const TopBtn = () => {
  const scrollBar = useRef();

  const [scrollPosition, setScrollPosition] = useState(0);

  const handleScroll = () => {
    const winScroll = document.documentElement.scrollTop;
    const height =
      document.documentElement.scrollHeight -
      document.documentElement.clientHeight;
    const scrolled = (winScroll / height) * 100;
    setScrollPosition(scrolled);
    if (winScroll > 0) {
      scrollBar.current.classList.add('bottom-4');
      scrollBar.current.classList.add('!opacity-100');
    } else {
      scrollBar.current.classList.remove('bottom-4');
      scrollBar.current.classList.remove('!opacity-100');
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);
  return (
    <div
      ref={scrollBar}
      onClick={() => window.scrollTo(0, 0)}
      className="z-50  inline-block w-14 h-14 opacity-0 rounded-full bg-light-green fixed cursor-pointer shadow-lg  --bottom-20 ltr:left-14 ltr:right-0 rtl:right-14 rtl:left-0 ">
      <img
        className="absolute top-[50%] left-[50%] -translate-x-[50%] -translate-y-[50%]"
        src={TopIcon}
        alt="top icon"
      />
    </div>
  );
};

export default TopBtn;
