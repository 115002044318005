import { Link } from 'react-router-dom';
const Link_1 = ({ link, section, tip, setActive, active }) => {
  return (
    <>
      <li onClick={setActive} className={` text-gray-300 ${active}`}>
        <Link to={link} title={tip} className=" hover:text-dark-black">
          {section}
        </Link>
      </li>
      <li>
        <span className="text-gray-300 mx-2">
          {document.body.dir === 'en' ? '  \\  ' : '  /  '}
        </span>
      </li>
    </>
  );
};

export default Link_1;
