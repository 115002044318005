const Input_1 = ({ label, id, name, type }) => {
  return (
    <div className="relative mb-10 ">
      <label className="leading-7 text-sm text-gray-600" htmlFor={id}>
        {/* {label} */}
      </label>
      <input
        className="w-full bg-white rounded border border-gray-300 focus:border-light-green focus:ring-2 focus:ring-light-green hover:border-light-green hover:ring-1 hover:ring-light-green text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
        type={type}
        placeholder={label}
        name={name}
        id={id}
      />
    </div>
  );
};

export default Input_1;
