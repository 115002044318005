// هنا يتم انشاء القيمي الافتراضية

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  isLoading: false,
  mains: null,
  faqs: null,
  post: null,
  errorMessage: null,
};
