import { combineReducers } from 'redux';
import { mainsReducer, faqsReducer, postReducer } from './main/main.reducer';

const rootReducer = () =>
  combineReducers({
    mains: mainsReducer,
    faqs: faqsReducer,
    post: postReducer,
  });
export default rootReducer;
