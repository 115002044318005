/* TODO ! FIX RTL Swiper*/
import { Suspense } from 'react';
import { Routes, Route, BrowserRouter as Router } from 'react-router-dom';
import {
  Header1,
  Scroll,
  Breadcrumb1,
  Index1,
  About1,
  ContactUs1,
  Faq1,
  Major,
  New1,
  NotFound,
  TopBtn,
  Footer1,
  Message,
  InternalPage,
} from './IC.App';
import {
  useDispatch,
  useSelector,
  useEffect,
  loadMainsAsync,
} from './utils/Redux.Elements';
require('./template.configs');

const App = () => {
  const dispatch = useDispatch();
  const { mains, errorMessage } = useSelector((state) => state.mains);
  useEffect(() => {
    dispatch(loadMainsAsync);
  }, [dispatch]);
  if (localStorage.getItem('configs')) {
    if (mains)
      localStorage.getItem('configs', JSON.stringify(mains.configs.general));
  } else {
    if (mains)
      localStorage.setItem('configs', JSON.stringify(mains.configs.general));
  }
  // console.log(JSON.parse(localStorage.configs.general));
  return (
    <div className="App">
      <Router>
        <Suspense>
          <Header1
            configs={mains && mains.configs.header}
            content={mains && mains.menus}
          />
          <Scroll />
          {errorMessage && (
            <Message
              message={errorMessage && errorMessage.message}
              code={errorMessage && errorMessage.code}
            />
          )}
          <Breadcrumb1 content={mains && mains.menus} />
          <Routes>
            <Route index path="/" element={<Index1 />} />
            <Route path="/about" element={<About1 />} />
            <Route path="/contact" element={<ContactUs1 />} />
            <Route path="/f-and-q" element={<Faq1 />} />
            <Route path="/Major/:slug" element={<Major />} />
            <Route path="/new/:slug" element={<New1 />} />
            <Route path="/page/:slug" element={<InternalPage />} />
            <Route path="/*" element={<NotFound />} />
          </Routes>
          <TopBtn />
          <Footer1 />
        </Suspense>
      </Router>
    </div>
  );
};
export default App;
