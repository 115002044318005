import { useState } from 'react';

const Copyrights_1 = () => {
  const [data] = useState([
    {
      src: 'Mail_Footer.svg',
    },
    {
      src: 'Facebook_Footer.svg',
    },
    {
      src: 'Twitter_Footer.svg',
    },
    {
      src: 'Instagram_Footer.svg',
    },
    {
      src: 'Youtube_Footer.svg',
    },
  ]);
  return (
    <>
      <p>Media Max TV© - {new Date().getFullYear()}</p>
      <div>
        <ul className="inline-flex">
          <li className="mx-1 opacity-80 hover:opacity-100">
            Terms and conditions
          </li>
          <li className="mx-1 opacity-80 hover:opacity-100 border-white rtl:border-r-2 ltr:border-l-2 px-2">
            privity Policy
          </li>
        </ul>
        <ul className="inline-flex my-4 md:my-0">
          {data.map((el, index) => (
            <li
              className="mx-1 w-10 lg:w-auto opacity-80 hover:opacity-100 cursor-pointer"
              key={index}>
              <img
                src={require(`@assets/icons/${el.src}`)}
                alt={`${el.src.split('.', 1)} Icon`}
                className="w-full h-auto max-h-5 "
                width={16}
                height={16}
              />
            </li>
          ))}
        </ul>
      </div>
    </>
  );
};

export default Copyrights_1;
