import { useState } from 'react';
import { Link } from 'react-router-dom';
import shareIcon from '@assets/icons/share.svg';
const Social_1 = () => {
  const [toggle, setToggle] = useState(false);
  const [links] = useState([
    {
      key: '2',
      icon: 'twitter.svg',
      href: 'https://www.twitter.com',
    },
    {
      key: '2',
      icon: 'youtube.svg',
      href: 'https://www.youtube.com',
    },
    {
      key: '3',
      icon: 'facebook.svg',
      href: 'https://www.facebook.com',
    },
    {
      key: '4',
      icon: 'instagram.svg',
      href: 'https://www.instagram.com',
    },
  ]);
  return (
    <div className=" container flex flex-row-reverse justify-between mt-24 py-5 border-y-[1px] border-y-gray-500 max-w-xl px-0">
      <button
        onClick={() => setToggle(!toggle)}
        className="relative self-start">
        <Link to={`#share`}>
          <img src={shareIcon} alt={'share icon'} width="60" />
        </Link>
      </button>

      <ul
        className={`flex justify-center ${
          toggle ? 'opacity-100' : 'opacity-0'
        }`}>
        {links.map((el, ind) => (
          <li className="relative" key={ind}>
            <Link to={`#share`}>
              <img
                src={require(`@assets/icons/${el.icon}`)}
                alt={'share icon'}
                width="60"
                className="w-full h-auto"
              />
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Social_1;
