import { useState } from 'react';
import Input1 from './../inputs/Input_1';
import TextArea1 from './ui/Text_Area_1';
import Button1 from '@views/include/ui/buttons/Button_1';
import { Tr } from '@assets/js/awds_function';

const Form_1 = () => {
  const [data] = useState([
    { label: 'input-name', id: 'name', name: 'name', type: 'text' },
    {
      label: 'input-phone-number',
      id: 'phone',
      name: 'phone',
      type: 'phone',
    },
    {
      label: 'input-subject',
      id: 'subject',
      name: 'subject',
      type: 'text',
    },
  ]);
  return (
    <>
      <h2 className="font-[600] text-2xl text-form-heading mb-5">
        {Tr('sections_1.contact-us.heading')}
      </h2>
      <form action="Post">
        {data.map((el, ind) => (
          <Input1
            label={Tr(`sections_1.contact-us.${el.label}`)}
            id={el.id}
            name={el.name}
            type={el.type}
            key={ind}
          />
        ))}
        <TextArea1
          name="text-area"
          placeholder={Tr('sections_1.contact-us.text-area-message')}
          id="text-area"
          cols="10"
          rows="5"
        />
        <Button1
          txt={Tr('sections_1.contact-us.btn')}
          css="w-[100%] lg:self-end lg:w-auto px-5 mt-5"
        />
      </form>
    </>
  );
};

export default Form_1;
