import { Tr, Truncate } from '@assets/js/awds_function.js';
import ContentCard1 from './Content_Card_1.jsx';
const Text_1 = () => {
  return (
    <>
      {' '}
      <div className="text">
        {' '}
        <h2 className=" heading">
          {Truncate(Tr('sections_1.why.heading'))}
        </h2>{' '}
        <p className="leading-10 lg:leading-5 xl:leading-7 lg:text-left rtl:lg:text-right lg:mb-5 text-justify">
          {' '}
          {Truncate(Tr('sections_1.why.desc'), 140)}{' '}
        </p>{' '}
      </div>{' '}
      <div className="flex flex-col gap-y-10 lg:mt-0 mt-10">
        {' '}
        <ContentCard1 />{' '}
      </div>{' '}
    </>
  );
};
export default Text_1;
