import { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import userIcon from '@assets/img/thumb.webp';
const ProfileDropDown = ({ class_p }) => {
  const [state, setState] = useState(false);
  const profileRef = useRef();

  const navigation = [
    { title: 'Dashboard', path: '/Dashboard' },
    { title: 'Settings', path: '/setting' },
    { title: 'Log out', path: '/logout' },
  ];

  useEffect(() => {
    const handleDropDown = (e) => {
      if (!profileRef.current.contains(e.target)) setState(false);
    };
    document.addEventListener('click', handleDropDown);
  }, []);

  return (
    <div className={`relative ${class_p}`}>
      <div className="flex items-center space-x-4">
        <button
          ref={profileRef}
          className="w-10 h-10 outline-none rounded-full ring-offset-2 ring-gray-200 ring-2 lg:focus:ring-light-green"
          onClick={() => setState(!state)}>
          <img
            src={userIcon}
            className="w-full h-full rounded-full"
            alt="profile"
          />
        </button>
        <div className="lg:hidden">
          <span className="block">Micheal John</span>
        </div>
      </div>
      <ul
        className={`bg-white top-12 right-0 rtl:lg:right-auto rtl:lg:left-0  mt-5 space-y-5 lg:absolute lg:border lg:rounded-md lg:text-sm lg:w-52 lg:shadow-md lg:space-y-0 lg:mt-0 ${
          state ? '' : 'lg:hidden'
        }`}>
        {navigation.map((item, idx) => (
          <li key={idx}>
            <Link
              to={item.path}
              className="block text-gray-600 lg:hover:bg-gray-50 lg:p-2.5">
              {item.title}
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default ProfileDropDown;
