import Text1 from './ui/Text_1';
import { lazy } from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

const Media1 = lazy(() => import('./ui/Media_1'));
const About_Us_1 = ({ content }) => {
  return (
    <section className="relative our-services">
      <div className="container flex flex-col lg:flex-row justify-center lg:justify-between  py-20">
        <div className="lg:max-w-[50%] order-2 lg:order-1">
          <Text1 heading={content.title} disc={content.subtitle} />
        </div>
        <div className="order-1 lg:order-2 ">
          {(content && <Media1 url={content && content.image} />) || (
            <Skeleton
              className="!w-[350px] !h-[300px] !mx-14 lg:!h-[400px] "
              baseColor={'#8ff1d7'}
            />
          )}
        </div>
      </div>
    </section>
  );
};
export default About_Us_1;
