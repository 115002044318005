import { useState } from 'react';

const Opening_Time_1 = () => {
  const [data] = useState([
    {
      days: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
      time: [
        { from: '06 AM', to: '08 PM' },
        { from: '06 AM', to: '08 PM' },
        { from: '09 AM', to: '08 PM' },
        { from: '09 AM', to: '08 PM' },
        { from: '06 AM', to: '08 PM' },
        { from: '06 AM', to: '08 PM' },
        { from: '09 AM', to: '12 PM' },
      ],
    },
  ]);
  return (
    <div className="col-span-3 lg:col-span-1 order-1 lg:order-none">
      <h2 className="border-b-2  max-w-[200px] pb-2 mb-2 ">Opening Time</h2>
      <div className="grid grid-cols-3">
        <div className="col-span-1">
          <ul>
            {data[0].days.map((el, ind) => (
              <li key={ind}>{el}</li>
            ))}
          </ul>
        </div>
        <div className="col-span-2">
          <ul>
            {data[0].time.map((el, ind) => (
              <li key={ind}>
                <span className="from">{el.from}</span>
                <span className="to">{el.to}</span>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Opening_Time_1;
