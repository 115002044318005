import { Link } from 'react-router-dom';
import { Tr } from '@functions/awds_function.js';
import Button1 from '@views/include/ui/buttons/Button_1';
import LottieFile2 from './ui/LottieFile_1';
const NotFound = () => {
  return (
    <section className="flex items-center h-full p-16 ">
      <div className="container flex flex-col items-center justify-center px-5 mx-auto my-8">
        <div className="text-center">
          <LottieFile2 />
          <p className="text-2xl font-semibold md:text-3xl bg-white lg:bg-transparent">
            {Tr('pages.not_found.heading')}
          </p>
          <p className="mt-4 mb-8 bg-white lg:bg-transparent">
            {Tr('pages.not_found.desc')}
          </p>
          <Link to={'/'} rel="noopener noreferrer" className="">
            <Button1 txt={Tr('pages.not_found.btn')} />
          </Link>
        </div>
      </div>
    </section>
  );
};
export default NotFound;
