import { useState } from 'react';
import { Tr, Truncate } from '@assets/js/awds_function';
const Card_1 = () => {
  const [data] = useState([
    { icon: 'Features_1.svg' },
    { icon: 'Features_2.svg' },
    { icon: 'Features_3.svg' },
  ]);

  return (
    <>
      {data.map((el, ind) => (
        <div
          key={ind}
          className=" flex flex-col items-center text-center lg:flex-row lg:text-start card ">
          <div className=" min-w-[6rem] relative hover:shadow-lg items-center  gradient-custom w-24 h-24 lg:mr-4 rtl:lg:mr-0 rtl:lg:ml-4 mb-2 lg:mb-0  rounded-full  border-border-color border-4">
            <img
              className="absolute -translate-x-[50%] -translate-y-[50%] left-[50%] top-[50%]"
              width="30"
              height="30"
              src={require(`@assets/icons/${el.icon}`)}
              alt={`${el.icon.split('.', 1)} Icon`}
            />
          </div>
          <div>
            <h3 className="text-[#2B4832] font-[600] text-xl mb-2">
              {Truncate(Tr('sections_1.why.features.title'), 25)} {ind}
            </h3>
            <p className="leading-6 max-w-[400px] lg:w-auto">
              {Truncate(Tr('sections_1.why.features.content'), 130)} {ind}
            </p>
          </div>
        </div>
      ))}
    </>
  );
};
export default Card_1;
