// هنا يتم جلب نوع الاكشين والتحديث على الاوبجيككت

import actionTypes from './main.actionTypes';
import initialStates from './main.initialStates';

const mainsReducer = (state = initialStates, { type, payload }) => {
  switch (type) {
    case actionTypes.MAIN_LOAD_START:
      return {
        ...state,
        isLoading: true,
        mains: null,
        errorMessage: null,
      };

    case actionTypes.MAIN_LOAD_SUCCESS:
      return {
        ...state,
        isLoading: false,
        mains: payload,
      };

    case actionTypes.FAQ_LOAD_SUCCESS:
      return {
        ...state,
        isLoading: false,
        faq: payload,
      };

    case actionTypes.MAIN_LOAD_ERROR:
      return {
        ...state,
        isLoading: false,
        errorMessage: payload,
      };

    default:
      return state;
  }
};
const faqsReducer = (state = initialStates, { type, payload }) => {
  switch (type) {
    case actionTypes.MAIN_LOAD_START:
      return {
        ...state,
        isLoading: true,
        mains: null,
        errorMessage: null,
      };

    case actionTypes.FAQ_LOAD_SUCCESS:
      return {
        ...state,
        isLoading: false,
        faqs: payload,
      };

    case actionTypes.MAIN_LOAD_ERROR:
      return {
        ...state,
        isLoading: false,
        errorMessage: payload,
      };

    default:
      return state;
  }
};
const postReducer = (state = initialStates, { type, payload }) => {
  switch (type) {
    case actionTypes.MAIN_LOAD_START:
      return {
        ...state,
        isLoading: true,
        mains: null,
        errorMessage: null,
      };

    case actionTypes.POST_LOAD_SUCCESS:
      return {
        ...state,
        isLoading: false,
        post: payload,
      };

    case actionTypes.MAIN_LOAD_ERROR:
      return {
        ...state,
        isLoading: false,
        errorMessage: payload,
      };

    default:
      return state;
  }
};

export { mainsReducer, faqsReducer, postReducer };
