import Text1 from './ui/Text_1';
import CardContent1 from './ui/Card_Content_1';
const Last_News_1 = ({ content, contentCard }) => {
  return (
    <section>
      <div className="container flex-col py-20">
        <div className="flex items-end justify-between">
          <Text1
            heading={(content && content.title) || ''}
            desc={(content && content.title) || ''}
          />
        </div>
        <div>
          <CardContent1 contentCard={contentCard} />
        </div>
      </div>
    </section>
  );
};
export default Last_News_1;
