import FaqsCard from './ui/FaqsCard_1';
import LottieFile from './ui/LottieFile_1';
import {
  useDispatch,
  useSelector,
  useEffect,
  loadFaqsAsync,
} from './../../../utils/Redux.Elements';
import 'react-loading-skeleton/dist/skeleton.css';
import Skeleton from 'react-loading-skeleton';
const Faq_1 = () => {
  const dispatch = useDispatch();
  const { faqs } = useSelector((state) => state.faqs);
  useEffect(() => {
    dispatch(loadFaqsAsync());
  }, [dispatch]);

  return (
    <section className="leading-relaxed max-w-screen-xl mt-12 mx-auto px-4 lg:px-8 relative">
      <LottieFile />
      <div className="space-y-3 text-center">
        {(faqs && (
          <h1 className="text-3xl text-light-green font-semibold">
            {faqs.faq_cats[0].title}
          </h1>
        )) || <Skeleton className="!max-w-[250px] !h-10" />}
        {(faqs && (
          <p className="text-gray-600 max-w-lg mx-auto text-lg">
            Answered all frequently asked questions, Still confused? feel free
            to contact us.
          </p>
        )) || <Skeleton className="!max-w-[450px] !h-6 mt-5" />}
      </div>
      <div className="mt-14 max-w-2xl mx-auto">
        {faqs &&
          faqs.faq_cats[0].faqs.map((item, idx) => (
            <FaqsCard key={idx} faqsList={item} />
          ))}
      </div>
    </section>
  );
};

export default Faq_1;
