import { useEffect, useRef } from 'react';
import Form1 from '../form/form_1';
import BrandLogo1 from './ui/Brand_Logo_1';
import ContactInfo1 from './ui/Contact_Info_1';
import OpeningTime1 from './ui/Opening_Time_1';
import SectionsLinks1 from './ui/Sections_Links_1';
import Copyrights1 from './ui/Copyrights_1';

const Footer_1 = () => {
  const contactForm = useRef();
  useEffect(() => {
    if (window.location.pathname === '/contact')
      contactForm.current.style = `display:none !important`;
    else contactForm.current.style = `display:inline-block !important`;
  }, []);
  return (
    <footer className="bg-light-green text-white  lg:pb-5 pb-20  relative  lg:mt-[400px] mt-96 ">
      <img
        className="absolute left-0 bottom-[100%] lg:bottom-[120%] -z-[999]"
        src={require('@assets/img/bg-footer.webp')}
        alt="bg footer"
      />
      <div className="container">
        <div className=" grid grid-cols-1 lg:grid-cols-3 ">
          <div className="order-2 lg:order-none  lg:col-span-2 grid grid-cols-1 lg:grid-cols-3 gap-x-5 grid-rows-2">
            <BrandLogo1 />
            <ContactInfo1 />
            <OpeningTime1 />
            <SectionsLinks1 />
          </div>
          <div className="lg:relative min-w-[300px] lg:-top-[50%]">
            <div
              ref={contactForm}
              className="lg:absolute lg:col-span-1  bg-white shadow-md rounded-xl flex flex-col w-[100%]  p-5 text-black items-stretch">
              <Form1 />
            </div>
          </div>
        </div>
        <div className="flex justify-between mt-10 flex-col-reverse lg:flex-row ">
          <Copyrights1 />
        </div>
      </div>
    </footer>
  );
};

export default Footer_1;
