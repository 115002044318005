import { useState } from 'react';
import { Link } from 'react-router-dom';
import { Tr } from '@assets/js/awds_function';

const Contact_Info_1 = () => {
  const [data] = useState([
    {
      titles: [
        {
          title: 'address-label',
        },
        {
          title: 'phone',
        },
        {
          title: 'email',
        },
      ],
      contact: [
        {
          way: " : Sana'a",
          url: '#',
        },
        {
          way: '+967 717 216 401',
          url: '/phone',
        },
        {
          way: 'example@examply.com',
          url: '/email',
        },
      ],
    },
  ]);

  return (
    <div className="col-span-3 lg:col-span-1 order-3 lg:order-none grid grid-rows-2 border-t-2 lg:border-none max-w-[200px] pt-2 mt-2 ">
      <div className="grid grid-cols-3">
        <ul className="col-span-1">
          {data[0].titles.map((el, ind) => (
            <li key={ind}>{Tr(`sections_1.contact-us.${el.title}`)}</li>
          ))}
        </ul>
        <ul className=" col-span-2">
          {data[0].contact.map((el, ind) => (
            <li key={ind} className="break-word">
              <Link to={el.url}>{el.way}</Link>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default Contact_Info_1;
