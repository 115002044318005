import { Tr, Truncate } from '@assets/js/awds_function';
import Button1 from '@views/include/ui/buttons/Button_1';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

import { Link } from 'react-router-dom';
const Text_1 = ({ heading, disc }) => {
  return (
    <div className="flex flex-col justify-between h-[100%] ">
      <div>
        {(heading && <h2 className="heading">{Truncate(heading, 25)}</h2>) || (
          <Skeleton
            baseColor={'#8ff1d7'}
            className="!w-[250px] mt-10 lg:mt-0 !h-10 !mb-10"
            count={1}
          />
        )}

        {(disc && (
          <p className="leading-10 text-justify">{Truncate(disc, 400)}</p>
        )) || (
          <Skeleton
            baseColor={'#8ff1d7'}
            className="!w-[260px]  md:!w-[400px] lg:!w-[500px]  !h-5 !my-2"
            count={5}
          />
        )}
      </div>
      <Link to="/about" className="mt-5">
        {/* <!-- <Button_1 :txt="$t('btn.more')" /> --> */}
        <Button1 txt={Tr('sections_1.about-us.btn')} />
      </Link>
    </div>
  );
};
export default Text_1;
